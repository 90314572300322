const PAGE_LINKS = [
  {
    DISPLAY: "About",
    LINK: "/about",
  },
  {
    DISPLAY: "Contact Us",
    LINK: "/contact",
  },
  {
    DISPLAY: "Products",
    CHILDREN: [
      {
        DISPLAY: "Chatbot",
        LINK: "/products/chatbot",
      },
      {
        DISPLAY: "E-commerce Solution",
        LINK: "/products/ecommerce-solutions",
      },
      {
        DISPLAY: "Transport Management System",
        LINK: "/products/transport-management-system",
      },
      {
        DISPLAY: "Point Of Sale System",
        LINK: "/products/point-of-sale",
      },
    ],
  },
  {
    DISPLAY: "Blogs",
    LINK: "/blogs",
  },
];

const PERK_DATA = [
  {
    TITLE: "Ideation",
    SUBTITLE:
      "We will help your idea abstraction and tailor it to fit the real world.",
    SRC: "ideation.png",
  },
  {
    TITLE: "Improve",
    SUBTITLE: "Optimize and improve your existing product with us",
    SRC: "improve.png",
  },
  {
    TITLE: "Increase",
    SUBTITLE:
      "Enhance your throughput and margin using our system, forged with the best technology",
    SRC: "increase.png",
  },
];

const WORK_INSIGHTS = {
  USERS: 8,
  REVIEWS: 20,
  CLIENTS: 7,
  PROJECTS: 14,
};

const FEATURE_CAROUSEL_LIST = [
  "AI Assistant",
  "Mobile Apps",
  "Web Apps",
  "Artificial Intelligence",
  "CRM Solution",
  "NFTs",
  "DApps",
  "Machine Learning",
  "Server Hosting",
];

const SOLUTIONS = [
  {
    HEADER: "Mobile App",
    TITLE: "Mobile Application Development",
    SUBTITLE:
      "With the help of our cutting-edge app development services, enhance your mobile experience. We produce dynamic and user-friendly apps that go above and above, from inception to launch.",
    SRC: "MobDev-SD.png",
    LIST_1: ["Scalable", "Service Cost Cutdown", "Low Maintenance Cost"],
    LIST_2: ["UI/UX", "Flexible"],
  },
  {
    HEADER: "Web App",
    TITLE: "Web Application Development",
    SUBTITLE:
      "Transform your digital presence with our expert web development services. Our team of skilled developers will bring your ideas to life and create an unforgettable online experience.",
    SRC: "WebDev-SD.png",
    LIST_1: ["Visually Stunning", "Hosting", "SEO Friendly"],
    LIST_2: ["Security", "Deployment", "Cost Efficient"],
  },
  {
    HEADER: "AI/ML",
    TITLE: "AI/ML Applications",
    SUBTITLE:
      "Experience the future of technology with our cutting-edge AI and ML solutions. From smart automation to conversational chatbots, we bring innovation to your business.",
    SRC: "AI-SD.png",
    LIST_1: ["Predictions", "Training", "Tailored to your Business"],
    LIST_2: ["NLP Projects", "Scalable"],
  },
  {
    HEADER: "Web3",
    TITLE: "Blockchain",
    SUBTITLE:
      "Get ready to experience the future of technology with our Blockchain-powered platform! Say goodbye to intermediaries and hello to secure, decentralized network.",
    SRC: "BChain-SD.png",
    LIST_1: ["Smart Contracts", "Tokens", "Decentralized"],
    LIST_2: ["Secure", "Non exploitable", "NFTs"],
  },
];

const TIMELINE = [
  {
    TITLE: "Ideation",
    SUBTITLE:
      "Ideation is the phase where brainstorming sessions are conducted, and ideas are generated. It's the time to think creatively and explore all possibilities",
  },
  {
    TITLE: "Research",
    SUBTITLE:
      "Research involves gathering relevant information, analyzing data, and identifying potential challenges or opportunities with regards to the project.",
  },
  {
    TITLE: "Design",
    SUBTITLE:
      "Designing a prototype of the product in order to see the workflows, style and get a glimpse of how the end product will look will convey the general idea of the product to the end users and stakeholders.",
  },
  {
    TITLE: "Develop",
    SUBTITLE:
      "Development is the step where the design is implemented into an application along with the required functionality by our efficient developers.",
  },
  {
    TITLE: "Testing",
    SUBTITLE:
      "In this phase we test out the usability, functionality, security, interface checks and the compatibility of the developed product.",
  },
  {
    TITLE: "Deployment",
    SUBTITLE:
      "Deployment being the last phase where the tested product is launched and made available to the end users.",
  },
];

const FEATURE_LISTS = {
  FEATURE_LIST_1: [
    {
      ICON: "🎨",
      TITLE: "Personalized",
      SUBTITLE:
        "Together, our team of professionals will determine your unique needs and objectives in order to create a solution that satisfies those demands.",
    },
    {
      ICON: "🚀",
      TITLE: "AI Powered",
      SUBTITLE:
        "Use AI-powered insights to gain data-driven solutions for optimizing your business.",
    },
    {
      ICON: "🤝",
      TITLE: "Client Centered",
      SUBTITLE:
        "Providing the best product for our clients and ensuring their satisfaction are our top priorities.",
    },
  ],
  FEATURE_LIST_2: [
    {
      ICON: "📈",
      TITLE: "Analytics",
      SUBTITLE:
        "Get accurate insights and analytics through our curated products and services.",
    },
    {
      ICON: "🌐",
      TITLE: "Server",
      SUBTITLE:
        "Avail the benefit of an efficient server cost on all our products and services.",
    },
    {
      ICON: "🔗",
      TITLE: "Blockchain",
      SUBTITLE:
        "Join us on the Blockchain venture and curated products such as DApps and NFTs.",
    },
  ],
};

const PRODUCTS = [
  {
    TITLE: "Dopamine.fit",
    LINK: "https://dopamine.fit/",
    SRC: "Dopamine.png",
    SUBTITLE: "A B2B SAAS platform for fitness business",
  },
  {
    TITLE: "Edjust",
    LINK: "https://edjust.co/",
    SRC: "ed.png",
    SUBTITLE:
      "Helping Parents discover, compare & book the right course with the relevant EdTech according to their needs. Giving absolute clarity for making the right decision for their child",
  },
  {
    TITLE: "Junqfoods",
    LINK: "https://play.google.com/store/apps/details?id=app.consumer.junqfoods&hl=en_IN&gl=US&pli=1",
    SRC: "jfood.png",
    SUBTITLE:
      "Serving over 10000+ Families in the Pandemic with Fresh Produce across Mumbai. Ideation of the process to the last mile delivery was smoothly enabled with technology",
  },
  {
    TITLE: "BWOW",
    LINK: "https://bwow.fit/",
    SRC: "bwow.png",
    SUBTITLE: "Socio E-commerce platform for fitness enthusiasts",
  },
  {
    TITLE: "Broadbox",
    LINK: "https://broadbox.co/",
    SRC: "bbox.png",
    SUBTITLE:
      "It's the solution to you wasting hours, browsing through an ocean of entertainment apps trying to stream your favourite Movies or Series",
  },
];

const REVIEWS = [
  {
    TEXT: "Our ed-tech aggregator Edjust, have been collaborating with AiBuddha for technical expertise and I have to say that we have had an immense success from our collaboration.",
    NAME: "Dushyant Panchal",
    COMPANY: "Edjust",
  },
  {
    TEXT: "Dopamine.fit is collaborating with Aibuddha for technological acumen. They have been tremendously helpful in providing us with the highest-quality options for our company's needs.",
    NAME: "Siddhesh Ghuge",
    COMPANY: "Dopamine.fit",
  },
  {
    TEXT: "Efficacy Global has been collaborating with AiBuddha for our technical solutions. Our partnership has been mutually beneficial for our digital marketing venture.",
    NAME: "Sanjay Panikar",
    COMPANY: "Efficacy Global",
  },
  {
    TEXT: "I strongly recommend AiBuddha to everyone interested in running a successful online business. Everything you need in one place.",
    NAME: "Mohit Kundalani",
    COMPANY: "Respondez",
  },
  {
    TEXT: "During the Covid Pandemic, we deployed AiBuddha's Chatbot to provide our fellow citizens with the necessary information on hospital beds, oxygen cylinder availability, and other crucial information. It was a impactful project.",
    NAME: "Harshal Patil",
    COMPANY: "Vasai-Virar Municipal Corporation",
  },
  {
    TEXT: "AiBuddha has been terrific to aid us develop our products and services. They have been extremely helpful in exhibiting us with the most ideal business possibilities.",
    NAME: "AD Colaco",
    COMPANY: "Fethom",
  },
];

const FAQS = [
  {
    QUESTION: "What services do you offer?",
    ANSWER:
      "We offer a wide range of tech services, including products and services in the domains of Artificial Intelligence, Machine Learning, Mobile Applications, Web Applications and Blockchain",
  },
  {
    QUESTION: "Why should you choose us?",
    ANSWER:
      "We offer a variety of products and services that work coherently with each other. We can have multiple technologies built into your single product/services, we also have a strong understanding of data and security which ensures us to create a tamper-proof product/service for our clients.",
  },
  {
    QUESTION: "Can you help improve existing products?",
    ANSWER:
      "Yes, we offer the service to scale and improve your existing product. We will ensure that the refined product will be much more secure, user-friendly and upto date with the industry standards present currently.",
  },
  {
    QUESTION: "How long does it take to get started with your services?",
    ANSWER:
      "We can typically get started within a few days of receiving your request, depending on the scope of the project and our current workload.",
  },
  {
    QUESTION: "How can I get in touch with you if I need help?",
    ANSWER:
      "You can contact us by email, phone number or chat with our AI Assistant on our website for getting in touch with us or for answering any of your queries.",
  },
];

const HOME_BANNER_DETAILS = {
  TITLE: "Build a strong digital presence and launch your success",
  SUBTITLE:
    "Save time and improve productivity with a customizable workflow, allowing you to automate repetitive tasks and focus on what matters most.",
  BUTTON_TEXT: "Get In Touch",
  SRC: "25.png",
};

const CONTACT_HELP_LIST = [
  "Finding the Right Solution",
  "Scale Existing Product",
  "Improving Business",
];

const CONTACT_TESTIMONIAL = {
  NAME: "Sanjay Panikar",
  DESIGNATION: "Efficacy Global",
  SRC: "/images/sanjay.png",
  COMMENT:
    "I don't know what else to say, it is simply unbelievable - we have had unimaginable growth after using this product",
};

const CONTACT_INFO = [
  {
    TITLE: `Bangalore Office`,
    INFO: `No : 5, 16th ‘c’ cross, \nMCHS Layout, BTM 2nd Stage, \nBangalore - 560068`,
  },
  {
    TITLE: `Mumbai Office`,
    INFO: `C-108, Shivsagar complex, \nStella, Vasai West - 401202`,
  },
  {
    TITLE: `Phone No`,
    INFO: `+91 8149712865`,
  },
  {
    TITLE: `Email ID`,
    INFO: `contact@aibuddha.in`,
  },
];

const OUR_JOURNEY_DATA = {
  2022: {
    "March 2022": [
      "AiBuddha was launched with a vision to revolutionize the industry with cutting-edge solutions 🎉",
    ],
    "May 2022": ["AiBuddha's team grew to 3 employees 🫂"],
    "June 2022": ["AiBuddha opened it's first office in Mumbai 🔓"],
    "August 2022": ["AiBuddha completed it's first project ✨"],
  },
  2023: {
    "January 2023": ["AiBuddha's team grew to 5 employees 🫂"],
    "March 2023": ["AiBuddha opened it's second office in Bangalore 🔓"],
    "April 2023": ["AiBuddha's team grew to 7 employees 🫂"],
  },
};

const TEAM = [
  {
    NAME: "Rohit Nair",
    DESIGNATION: "Founder",
    SRC: "/images/team/rohit.png",
  },
  {
    NAME: "Sangeet More",
    DESIGNATION: "Project Manager",
    SRC: "/images/team/sangeet.png",
  },
  {
    NAME: "Atiq Shaikh",
    DESIGNATION: "Backend Developer",
    SRC: "/images/team/atiq.png",
  },
  {
    NAME: "Sreyas Alavoor",
    DESIGNATION: "Full Stack Developer",
    SRC: "/images/team/sreyas.png",
  },
  {
    NAME: "Rafi Mohammed",
    DESIGNATION: "Flutter Developer",
    SRC: "/images/team/rafi.png",
  },
  {
    NAME: "Keerthi Gopika",
    DESIGNATION: "UI/UX Designer",
    SRC: "/images/team/keerthi.png",
  },
  {
    NAME: "Shreehdar Mathialagan",
    DESIGNATION: "Flutter Developer",
    SRC: "/images/team/shreedhar.png",
  },
  {
    NAME: "Surabhi Moharir",
    DESIGNATION: "UI/UX Designer",
    SRC: "/images/team/surabhi.png",
  },
  {
    NAME: "Prateek Aher",
    DESIGNATION: "Flutter developer",
    SRC: "/images/team/prateek.png",
  },
  {
    NAME: "Rutuja Ghuge",
    DESIGNATION: "Software Intern",
    SRC: "/images/team/rutuja.png",
  },
];

const ABOUT_BANNER_DETAILS = {
  TITLE: "We cater your needs with our cutting-edge solutions",
  SUBTITLE: "",
  BUTTON_TEXT: "Get In Touch",
  SRC: "aboutUsPageBanner.png",
};

const CHATBOT_BANNER_DETAILS = {
  TITLE: "Managing your Business with our chatbot is just a click away",
  SUBTITLE: "",
  BUTTON_TEXT: "Get In Touch",
  SRC: "chatbot banner image.png",
};

const TMS_BANNER_DETAILS = {
  TITLE:
    "Streamline operations, enhance productivity, and gain deeper insights into your logistics processes with our Transport Management System",
  SUBTITLE: "",
  BUTTON_TEXT: "Get In Touch",
  SRC: "tms/banner2.png",
  TITLE_PROPS: {
    style: {
      fontSize: "32px",
      marginBottom: "40px",
    },
  },
  IMAGE_PROPS: {
    style: {
      width: "450px",
    },
  },
};

const POS_BANNER_DETAILS = {
  TITLE:
    "Enhance your retail operations with AiBuddha's powerful and versatile POS system.",
  SUBTITLE: "",
  BUTTON_TEXT: "Get In Touch",
  SRC: "pos/banner.png",
  TITLE_PROPS: {
    style: {
      fontSize: "32px",
      marginBottom: "40px",
    },
  },
  IMAGE_PROPS: {
    style: {
      width: "560px",
      margin: " 10px 10px 0",
    },
  },
};

const ECOMMERCE_BANNER_DETAILS = {
  TITLE: "Get in touch to know more about our E-commerce Solution",
  SUBTITLE: "",
  BUTTON_TEXT: "Get In Touch",
  SRC: "ecommerce-footer.png",
};

const CLIENT_LOGOS = ["edjust_logo.png", "dopamine_logo.png", "bwow_logo.png"];

const CLIENT_LOGOS_WITH_TEXT = [
  "edjust_logo_text.png",
  "dopamine_logo_text.png",
  "bwow_logo_text.png",
];

const FORMSPREE_ENDPOINT = "https://formspree.io/f/meqwwrrp";

const BLOG_API_ENDPOINT = "https://blog.aibuddha.in";
const BLOG_API_KEY =
  "e2b12ecc116d49295bc6639521c2ea47f3878b388064bba66aeda0f53e3bc07c957ce0d90a7239f09f71769f0aa01cbd09f0b5fc1e52f2a6319007f6b8b036f4ec3dca4150195cc4e2d8310b39e4326ffabf496be1da6b7b98a7d9c0680a78033f6cd81f964b8eb6380158a893d68d76be77479a05e3c569fe6acbafe1111200";

const SUBSCRIBE_API_KEY =
  "acf45a77acbe2d72149312955e09e4078cf32b7bded585be242326fa212bc22a20b67a29c24f9fefde82e4496c28a5ab7cbec00c041f9a26a4204842956c72492daab3bd956277cc409a797840640360dfc40d5079f8a497b70707da6f8a1f9dd261e59f1aab39d2430c1509526acb69c425c4aa6321b057132d160ff8741685";

export const CONSTANTS = {
  PAGE_LINKS,
  PERK_DATA,
  WORK_INSIGHTS,
  FEATURE_CAROUSEL_LIST,
  SOLUTIONS,
  TIMELINE,
  FEATURE_LISTS,
  PRODUCTS,
  REVIEWS,
  FAQS,
  HOME_BANNER_DETAILS,
  CONTACT_HELP_LIST,
  CONTACT_TESTIMONIAL,
  CONTACT_INFO,
  OUR_JOURNEY_DATA,
  TEAM,
  ABOUT_BANNER_DETAILS,
  CHATBOT_BANNER_DETAILS,
  CLIENT_LOGOS,
  CLIENT_LOGOS_WITH_TEXT,
  FORMSPREE_ENDPOINT,
  BLOG_API_ENDPOINT,
  BLOG_API_KEY,
  SUBSCRIBE_API_KEY,
  ECOMMERCE_BANNER_DETAILS,
  TMS_BANNER_DETAILS,
  POS_BANNER_DETAILS,
};
